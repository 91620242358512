const photos = {
  photosText0: "ACTUALMENTE SELECCIONADO -",
  photosText1: "ACTUALMENTE SELECCIONADO -",
  photosText2: "- Artículo #",
  photosText3: "Altura:",
  photosText4: "Ancho:",
  photosText5: "Precio Unitario Actual:",
  photosText6: "Este artículo está actualmente seleccionado para tu cotización.",
  photosText7: "Ver opciones de nuestra red de socios",
  photosText8: "Cargar opciones",
  photosText9: "No hay otras opciones disponibles.",
  photosText10: "OPCIÓN #",
  photosText11: "- Artículo #",
  photosText12: "Altura:",
  photosText13: "Ancho:",
  photosText14: "Precio Unitario Ajustado: ",
  photosText15: "ya no estará disponible si se selecciona",
  photosText16: "estará disponible si se selecciona",
  photosText17: "será sustituido por",
  photosText18: "si se selecciona",
  photosText19: "ya no será sustituido si se selecciona",
  photosText20: "Precio Unitario Ajustado: ",
  photosText21: "Calculando...",
  photosText22: "Seleccionar artículo",
  photosText23: "Volver",
  photosText24: "Precios unitarios ajustados",
  photosText25: "Los precios unitarios ajustados reflejan los costos adicionales incurridos en el resto del pedido como resultado de la selección del artículo.",
  photosText26: "Cerrar",

  quoteItemQueryText1: "Ocurrió un error al cargar la foto predeterminada, asegúrate de tener conexión a internet o contacta a Jules en",
  quoteItemQueryText2: "Ocurrió un error al cargar la foto predeterminada, contacta a Jules en",
  quoteItemQueryText3: "para asistencia.",
  quoteItemQueryText4: "Volver",
  quoteItemQueryText5: "Intentar de nuevo",

  photoOptionsQueryErrorText1: "Ocurrió un error al cargar las opciones de fotos, asegúrate de tener conexión a internet o contacta a Jules en",
  photoOptionsQueryErrorText2: "Ocurrió un error al cargar las opciones de fotos, contacta a Jules en",
  photoOptionsQueryErrorText3: "para asistencia.",
  photoOptionsQueryErrorText4: "Volver",
  photoOptionsQueryErrorText5: "Intentar de nuevo",

  useForceQuoteItemText1: "Exitoso",
  useForceQuoteItemText2: "Opción seleccionada exitosamente.",
  useForceQuoteItemText3: "Error",
  useForceQuoteItemText4: "La opción no pudo ser seleccionada. Contacta a jules@bidscape.com para ayuda.",
};

export default photos; 