import { Container, Title, Text, Space, SimpleGrid, Card, Image, Group, Button, Badge, Divider } from "@mantine/core";
import { useQuery, gql } from "@apollo/client";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "usehooks-ts";
import { formatMarkdown, getImageUrl } from "../utils/utils";
import Loader from "../components/Loader";
import Conditional from "../components/Conditional";
import { Plant } from "../typing/gql.public.schema";

// GraphQL query to fetch top plants
const TOP_PLANTS_QUERY = gql`
  query TopPlants($count: Int!, $state: String) {
    popularPlantsTTM(count: $count, state: $state) {
      id
      latinName
      commonName
      highlights
      mostPhotographedSize {
        id
        name
      }
      mostPhotographedSizePhotos {
        id
        filename
      }
    }
  }
`;

const TopPlantsShowcase: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 768px)");
  
  const { loading, error, data } = useQuery(TOP_PLANTS_QUERY, {
    variables: { count: 100, state: "CA" },
    context: { public: true },
    fetchPolicy: "cache-and-network",
  });

  if (loading && !data) return <Loader fullPage={true} />;
  
  if (error && !data) {
    return (
      <Container>
        <Title order={1} ta="center" mt="xl">
          {t("topPlantsShowcaseTitle")}
        </Title>
        <Space h="md" />
        <Text color="red" ta="center">
          {t("topPlantsErrorMessage")}
        </Text>
      </Container>
    );
  }

  const plants: Plant[] = data?.popularPlantsTTM || [];
  
  // Divide plants into tiers
  const featuredPlants = plants.slice(0, 10);
  const secondTierPlants = plants.slice(10, 40);
  const remainingPlants = plants.slice(40);

  const navigateToPlant = (plant: Plant) => {
    const formattedName = `${plant.latinName}-${plant.commonName}`.replace(/\s+/g, '-');
    navigate(`/plant/${plant.id}/${formattedName}`);
  };

  // Inline styles for card hover effect
  const cardHoverStyle = {
    cursor: 'pointer',
    transition: 'background-color 0.2s ease',
  };

  return (
    <Container size="xl">
      {/* @ts-ignore */}
      <Helmet title={t("topPlantsShowcaseTitle")} />
      
      <Title order={1} ta="center" mt="xl" size="h1" style={{ fontSize: '2.5rem' }}>
        {t("topPlantsShowcaseTitle")}
      </Title>
      
      <Text size="xl" ta="center" mb="xl" c="dimmed">
        {t("topPlantsShowcaseSubtitle")}
      </Text>

      {/* Featured Plants (Top 10) */}
      <Title order={2} mb="md">
        {t("featuredPlantsTitle")}
      </Title>
      
      <SimpleGrid cols={isMobile ? 1 : 2} spacing="xl" mb="xl">
        {featuredPlants.map((plant, index) => (
          <Card key={plant.id} shadow="sm" padding="lg" radius="md" withBorder>
            {plant.mostPhotographedSizePhotos && plant.mostPhotographedSizePhotos.length > 0 && (
              <Card.Section>
                <Image
                  src={getImageUrl(plant.mostPhotographedSizePhotos[0]?.filename)}
                  height={250}
                  alt={`Photo for ${plant.latinName} (${plant.commonName})`}
                />
              </Card.Section>
            )}
            
            <Group justify="apart" mt="md" mb={0}>
              <Text fw={500} size="lg">{plant.latinName}</Text>
              <Badge color="green" variant="light" size="xl">
                # {index + 1}
              </Badge>
            </Group>
            
            <Text size="md" c="dimmed" mb="md">
              {plant.commonName}
            </Text>
            
            <Conditional renderIf={!!plant.highlights}>
              <div 
                className="highlights" 
                dangerouslySetInnerHTML={{ __html: formatMarkdown(plant.highlights || "") }} 
              />
            </Conditional>
            
            <Button 
              variant="light" 
              color="green" 
              fullWidth 
              mt="md" 
              radius="md"
              size="md"
              fw={600}
              onClick={() => navigateToPlant(plant)}
            >
              {t("topPlantsViewDetailsButton")}
            </Button>
          </Card>
        ))}
      </SimpleGrid>

      {/* Second Tier Plants (11-40) */}
      <Divider my="xl" />
      <Title order={2} mb="md" size="h2">
        {t("popularPlantsTitle")}
      </Title>
      
      <SimpleGrid cols={isMobile ? 1 : 3} spacing="md" mb="xl">
        {secondTierPlants.map((plant, index) => (
          <Card key={plant.id} shadow="sm" padding="md" radius="md" withBorder>
            {plant.mostPhotographedSizePhotos && plant.mostPhotographedSizePhotos.length > 0 && (
              <Card.Section>
                <Image
                  src={getImageUrl(plant.mostPhotographedSizePhotos[0]?.filename)}
                  height={150}
                  alt={`Photo for ${plant.latinName} (${plant.commonName})`}
                />
              </Card.Section>
            )}
            
            <div style={{ display: 'flex', marginTop: 'var(--mantine-spacing-md)', marginBottom: 0, position: 'relative' }}>
              <div style={{ flex: 1, overflow: 'hidden', paddingRight: 60 }}>
                <Text fw={500} size="md" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {plant.latinName}
                </Text>
              </div>
              <Badge 
                color="green" 
                variant="light" 
                size="lg" 
                style={{ position: 'absolute', right: 0, top: 0 }}
              >
                # {index + 11}
              </Badge>
            </div>
            
            <Text 
              size="sm" 
              c="dimmed" 
              mb="md"
              style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
              {plant.commonName}
            </Text>
            
            <Button 
              variant="light" 
              color="green" 
              fullWidth 
              mt="sm" 
              size="sm"
              onClick={() => navigateToPlant(plant)}
            >
              {t("topPlantsViewDetailsButton")}
            </Button>
          </Card>
        ))}
      </SimpleGrid>

      {/* Remaining Plants (41-100) */}
      <Divider my="xl" />
      <Title order={2} mb="md" size="h2">
        {t("morePlantsTitle")}
      </Title>
      
      <SimpleGrid cols={isMobile ? 1 : 4} spacing="sm">
        {remainingPlants.map((plant, index) => (
          <Card 
            key={plant.id} 
            shadow="sm" 
            padding="sm" 
            radius="md" 
            withBorder
            onClick={() => navigateToPlant(plant)}
            style={cardHoverStyle}
            onMouseOver={(e) => {
              e.currentTarget.style.backgroundColor = '#237d2c'; // Dark green color
              e.currentTarget.style.color = 'white'; // Change text to white on hover
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.backgroundColor = '';
              e.currentTarget.style.color = ''; // Reset text color
            }}
          >
            <Group justify="apart" wrap="nowrap" style={{ width: '100%' }}>
              <Group h="xs" wrap="nowrap" style={{ flex: 1 }}>
                <Text c="green" fw={600} size="sm" style={{ flexShrink: 0 }}>
                  {index + 41}.
                </Text>
                <Text size="sm" lineClamp={isMobile ? undefined : 1} fw={500}>
                  {plant.commonName || plant.latinName}
                </Text>
              </Group>
              <Text c="green" fw={600} style={{ marginLeft: 'auto' }}>→</Text>
            </Group>
          </Card>
        ))}
      </SimpleGrid>
      
      <Space h="xl" />
    </Container>
  );
};

export default TopPlantsShowcase; 