const landing = {
  loginText1: "Correo de Verificación",
  loginText2: "Para proteger tu cuenta, necesitamos enviarte un correo con un enlace para finalizar tu inicio de sesión.",
  loginText3: "Correo electrónico",
  loginText4: "Se requiere un correo electrónico válido",
  loginText5: "Obtener Correo de Verificación",
  loginText6: "Ayuda",
  loginText7: "Cómo completar el inicio de sesión",
  loginText8: "Ingresa tu correo para recibir un enlace mágico que te permitirá continuar automáticamente después de hacer clic. Este paso adicional ayuda a proteger tu cuenta de accesos no autorizados, y puede ocurrir ocasionalmente o si accedes a nuestro sitio desde diferentes dispositivos.",
  loginText9: "Cerrar",

  authenticateLinkText1: "Autenticando enlace",
  authenticateLinkText2: "Por favor espera mientras verificamos tu enlace de inicio de sesión.",

  useLinkAuthenticatorText1: "Error",
  useLinkAuthenticatorText2: "Error al autenticar el enlace",

  useLoginText1: "¡Correo enviado!",
  useLoginText2: "¡Correo enviado, revisa tu bandeja de entrada para continuar!",
  useLoginText3: "Error",
  useLoginText4: "¡Ocurrió un error al enviar el enlace, por favor intenta de nuevo!",

  linkSent0: "Enlace de inicio de sesión enviado exitosamente",
  linkSent1: "Revisa tu correo,",
  linkSent2: "busca en todas las carpetas",
  linkSent3: "Te enviamos un correo con un enlace para el paso final. Debería estar en tu bandeja de entrada, pero si no lo ves, revisa tus carpetas de correo no deseado o spam.",
  linkSent4: "Busca un correo con el asunto:",
  linkSent5: "Completa tu inicio de sesión en Bidscape",
  linkSent6: "¿No lo encuentras? Envía un correo a",
  linkSent7: "para obtener ayuda.",
  linkSent8: "Volver al inicio de sesión",
};

export default landing; 