const browseTranslations = {
  plantDetailsText1: "Frecuentemente comprado con",

  plantDetailsAddToProjectText1: "Agregar a",
  plantDetailsAddToProjectText2: "Esta planta y tamaño ya está en tu proyecto, hacer clic en el botón solo actualizará la cantidad.",
  plantDetailsAddToProjectText3: "Ver cotización",
  plantDetailsAddToProjectText4: "Agregar a cotización",

  plantDetailsLocationInformationText1: "Ubicación",

  plantDetailsQueryError1: "Ocurrió un error al cargar la planta, asegúrate de tener conexión a internet o contacta a Jules en",
  plantDetailsQueryError2: "Ocurrió un error al cargar la planta, contacta a Jules en",
  plantDetailsQueryError3: "para asistencia.",
  plantDetailsQueryError4: "Intentar de nuevo",

  plantDetailsPlantSizePhotosText1: "No hay fotos disponibles para la combinación actual de planta y tamaño.",

  categoriesListingText1: "Explorar categorías",

  plantsListingText1: "Ver todas las categorías",
  
  // Top Plants Showcase translations
  topPlantsShowcaseTitle: "Las 100 Plantas Más Populares de CA",
  topPlantsShowcaseSubtitle: "Descubre las plantas más populares en California",
  featuredPlantsTitle: "Más Vendidas",
  popularPlantsTitle: "Opciones Populares",
  morePlantsTitle: "Más Plantas Excelentes",
  topPlantsRankingPrefix: "#",
  topPlantsViewDetailsButton: "Ver Detalles",
  topPlantsErrorMessage: "Error al cargar las plantas. Por favor intenta más tarde.",
};

export default browseTranslations; 