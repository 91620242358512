const estimate = {
  estimateText1: "Estimación",
  estimateText2: "Verificando disponibilidad",
  estimateText3: "Fecha Estimada de Entrega:",
  estimateText4: "Aviso de Estimación:",
  estimateText5: "Los precios a continuación son el",
  estimateText6: "máximo",
  estimateText7: "que se te cobrará. Hemos calculado precios que",
  estimateText8: "garantizamos",
  estimateText9: "para que puedas usarlos directamente sin preocuparte por excesos o márgenes de seguridad. Sin embargo, no conocemos la disponibilidad o el precio final (más bajo) hasta que hagas clic en el botón de abajo para verificarlo, lo que puede tomar hasta 24 horas.",
  estimateText10: "Aviso de Estimación:",
  estimateText11: "Los precios a continuación son",
  estimateText12: "estimados",
  estimateText13: "basados en un análisis detallado. No conocemos la disponibilidad o el precio final hasta que hagas clic en el botón de abajo para verificarlo, lo que puede tomar hasta 24 horas.",
  estimateText14: "por",
  estimateText15: "menos de",
  estimateText16: "cada uno y",
  estimateText16_1: "total",
  estimateText17: "Eliminar",
  estimateText18: "fue eliminado",
  estimateText19: "Agregar",
  estimateText20: "Listo para comprar",
  estimateText21: "Volver",
  estimateText22: "Compartir",
  estimateText23: "Descargar estimación como Excel (.xlsx)",
  estimateText24: "Compartir estimación",
  estimateText25: "Compartir esta estimación con:",
  estimateText26: "Correo electrónico",
  estimateText27: "Se requiere un correo electrónico válido",
  estimateText28: "Compartir",
  estimateText29: "Agregar planta",
  estimateText30: "¿Te gustaría agregar una planta?",
  estimateText31: "Confirmar",
  estimateText32: "Eliminar Planta",
  estimateText33: "¿Te gustaría eliminar la planta?",
  estimateText34: "Confirmar",
  estimateText35: "Contrato y Retención",
  estimateText36: "¿Listo para asegurar tu material vegetal con mucha anticipación a tu fecha de entrega proyectada? Por favor envía un correo a",
  estimateText37: "para discutir el cultivo por contrato o la retención a largo plazo de tu material vegetal.",
  estimateText38: "¿Listo para comprar?",
  estimateText39: "¿Listo para programar la entrega inmediata? La fecha estimada de entrega que tenemos registrada es {{deliveryDate}}. Si estás listo para ordenar, envía un correo a",
  estimateText40: "y háznoslo saber para que podamos procesar tu pedido para el envío más próximo disponible.",
  estimateText41: "individualmente",
  estimateText42: "en paquete",
  estimateText43: "o",
  estimateText44: "Subtotal:",
  estimateText45: "Entrega estimada",
  estimateText46: "Subtotal:",
  estimateText47: "- Tu total de entrega se basa en una entrega única para todos los materiales listados. Las entregas divididas resultarán en costos adicionales.",
  estimateText48: "Subtotal individual: ",
  estimateText49: "Subtotal en paquete: ",
  estimateText50: "ahorrado",
  estimateText60: "Cerrar",
  estimateText61: "Frecuentemente comprado con otros artículos en tu proyecto",
  estimateText62: "Agregar planta",
  estimateText63: "Excel (.xlsx)",
  estimateText64: "Fecha Estimada de Entrega",
  estimateText65: "Compartir",
  estimateText66: "Esta estimación ha expirado y ha sido archivada. Para renovarla, por favor contacta a",
  estimateText67: "Cargo por Servicio",

  estimateQueryErrorText1: "Ocurrió un error al cargar tu estimación, asegúrate de tener conexión a internet o contacta a Jules en",
  estimateQueryErrorText2: "Ocurrió un error al cargar tu estimación, contacta a Jules en",
  estimateQueryErrorText3: "para asistencia.",
  estimateQueryErrorText4: "Volver",
  estimateQueryErrorText5: "Intentar de nuevo",

  estimateItemAvailabilityText1: "Cargando disponibilidad...",
  estimateItemAvailabilityText2: "Baja Disponibilidad",
  estimateItemAvailabilityText3: "Disponibilidad Limitada",
  estimateItemAvailabilityText4: "Disponible",
};

export default estimate; 