const paymentTranslations = {
  useStripeCheckoutText1: "Error de Pago",
  useStripeCheckoutText2: "Algo salió mal. Por favor intenta de nuevo.",
  useStripeCheckoutText3: "Pago Exitoso",
  useStripeCheckoutText4: "Tu pago fue exitoso.",

  paymentText1: "Detalles del Pago",
  paymentText2: "Por favor ingresa los detalles de tu pago para completar tu compra.",
  paymentText3: "Error de Pago",
  paymentText4: "Algo salió mal al procesar tu pago. Por favor intenta más tarde.",

  checkoutFormText1: "Subtotal",
  checkoutFormText2: "Cargo por entrega",
  checkoutFormText3: "Impuestos",
  checkoutFormText4: "Cargo por servicio",
  checkoutFormText5: "Total a pagar",
  checkoutFormText6: "Pagar",
  checkoutFormText7: "Cargo por procesamiento de tarjeta de crédito",
};

export default paymentTranslations; 