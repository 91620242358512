const plantListingBase = {
  plantsQueryErrorText1: "Ocurrió un error al cargar las plantas, asegúrate de tener conexión a internet o contacta a Jules en",
  plantsQueryErrorText2: "Ocurrió un error al cargar las plantas, contacta a Jules en",
  plantsQueryErrorText3: "para asistencia.",
  plantsQueryErrorText4: "Volver",
  plantsQueryErrorText5: "Intentar de nuevo",

  plantListingBasePlantItemText1: "Ver",

  PlantsListingSearchBarText1: "Buscar plantas...",
};

export default plantListingBase; 