const wrapperTranslations = {
  footerText1: `©Copyright ${new Date().getFullYear()} Bidscape, Inc`,
  footerText2: "Privacy",
  footerText3: "Terms",

  headerText1: "Login",
  headerText2: "Switch user",
  headerText3: "Logout",
  headerText4: "Navigation",
  headerText5: "Toggle theme",
  headerText6: "Switch User",
  headerText7: "Close",
  headerText8: "Switch language",

  switchYardText1: "Search user",

  // use switch user
  useSwitchUserText1: "Success",
  useSwitchUserText2: "selected as active user",
  useSwitchUserText3: "Error",
  useSwitchUserText4: "Error switching user",
};

export default wrapperTranslations;
