const publicRequest = {
  requestsText1: "Entrada de plantas",
  requestsText2: "Elige tus plantas",
  requestsText3: "Información del proyecto",
  requestsText4: "Cuéntanos sobre tu proyecto",
  requestsText5: "Obtener estimación",
  requestsText6: "Ver precios, disponibilidad y fotos de plantas",

  requestPlantEntryItemText1: "Eliminar",

  requestPlantEntryText1: "Entrada de Plantas",
  requestPlantEntryText2: "Elige tus plantas y especificaciones de tamaño",
  requestPlantEntryText3: "Agregar planta",
  requestPlantEntryText4: "Planta:",
  requestPlantEntryText5: "Tamaño:",
  requestPlantEntryText6: "Cantidad:",
  requestPlantEntryText8: "Guardar y Continuar",
  requestPlantEntryText9: "Volver a Categorías",
  requestPlantEntryText10: "Agregar más elementos",
  requestPlantEntryText11: "Agregar más plantas",
  requestPlantEntryText12: "Agregar plantas",

  requestProjectMinimumText: "Tu proyecto está por debajo de nuestro mínimo",

  // ... continuing with more translations
};

export default publicRequest; 