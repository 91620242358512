import { Button, Grid, Group, NumberInput, Select, Space, Text } from "@mantine/core";
import React from "react";
import { styled } from "styled-components";
import { useMediaQuery } from "usehooks-ts";
import Conditional from "../../components/Conditional";
import { DeepPartial } from "../../typing/declarations";
import { Size } from "../../typing/gql.public.schema";
import { CartItem } from "../../typing/gql.schema";

type Props = {
  sizes: Array<Size>;
  item: DeepPartial<CartItem>;
  onQuantityChange: (e: number) => void;
  onSizeSelect: (e: DeepPartial<Size>) => void;
  finishEditCartItem: () => void;
  cancelEditCartItem: () => void;
};

const EditPlantEntryItem: React.FC<Props> = ({ sizes, item, onQuantityChange, onSizeSelect, finishEditCartItem, cancelEditCartItem }) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  return (
    <Container>
      <Conditional renderIf={!isMobile}>
        <Grid columns={24}>
          <Grid.Col span={{ base: 24, sm: 24, md: 20 }}>
            <Group>
              <NumberInput className="quantity-input" defaultValue={item.quantity} onChange={(value) => onQuantityChange(Number(value))} />
              <Select
                className="size-select"
                defaultValue={item.size?.id}
                data={sizes?.map((size) => ({ value: size.id, label: size.name }))}
                onChange={(value) => onSizeSelect(sizes?.find((size) => size.id === value)!)}
              />
              <Text className="text">
                <i>{item?.plant?.latinName}</i>&nbsp; ({item?.plant?.commonName})
              </Text>
            </Group>
          </Grid.Col>
          <Grid.Col span={{ base: 24, sm: 24, md: 4 }}>
            <Group justify="right">
              <Button.Group>
                <Button 
                  variant="outline" 
                  color="green"
                  data-testid="edit-cart-item" 
                  onClick={finishEditCartItem}
                >
                  Save
                </Button>
                <Button variant="outline" color="red" onClick={cancelEditCartItem}>
                  Cancel
                </Button>
              </Button.Group>
            </Group>
          </Grid.Col>
        </Grid>
      </Conditional>
      <Conditional renderIf={isMobile}>
        <Text className="text">
          <strong>Plant:</strong>&nbsp;
          <i>{item?.plant?.latinName}</i>&nbsp; ({item?.plant?.commonName})
        </Text>
        <Space h={5} />
        <NumberInput label="Quantity" defaultValue={item.quantity} onChange={(value) => onQuantityChange(Number(value))} />
        <Space h={5} />
        <Select
          label="Size"
          defaultValue={item.size?.id}
          data={sizes?.map((size) => ({ value: size.id, label: size.name }))}
          onChange={(value) => onSizeSelect(sizes?.find((size) => size.id === value)!)}
        />
        <Space h={15} />
        <Group justify="center" gap="xs">
          <Button variant="outline" data-testid="edit-cart-item" onClick={finishEditCartItem}>
            Save
          </Button>
          <Button variant="outline" color="red" onClick={cancelEditCartItem}>
            Cancel
          </Button>
        </Group>
      </Conditional>
    </Container>
  );
};

const Container = styled.div`
  .quantity-input {
    width: 100px !important;
  }

  .size-select {
    width: 200px !important;
  }

  .text {
    margin: auto;
    margin-left: 0px;
  }

  // Add z-index and ensure buttons are visible
  .mantine-Button-root {
    position: relative;
    z-index: 1;
  }
`;

export default EditPlantEntryItem;
