const dashboard = {
  action_queue_card_title: "Cola de Acciones",
  action_queue_card_keep_button: "Mantener abierto",
  action_queue_card_archive_button: "Archivar proyecto",

  dashboardText1: "Panel de Control",
  dashboardText2: "Crear nueva solicitud",

  requestsPanelText1: "Solicitudes",
  requestsPanelText2: "Solicitudes Abiertas",
  requestsPanelText3: "Solicitudes Cerradas",

  openRequestsText1: "Solicitudes Abiertas",
  openRequestsText2: "Solicitud #",
  openRequestsText3: "Creada:",
  openRequestsText4: "Por determinar (entrega por fases)",
  openRequestsText5: "Ver",
  openRequestsText6: "Procesando",
  openRequestsText7: "Actualmente no tienes solicitudes abiertas. ¡Las nuevas solicitudes aparecerán una vez recibidas!",
  openRequestsText8: "Esta solicitud está siendo procesada por nuestro equipo. Recibirás un correo cuando esté lista para ser vista.",
  openRequestsText9: "Sin solicitudes abiertas",

  closedRequestsText1: "Sin Solicitudes Cerradas",
  closedRequestsText2: "Actualmente no tienes solicitudes cerradas. ¡Las solicitudes aparecerán aquí cuando se cierren!",
  closedRequestsText3: "Solicitud #",
  closedRequestsText4: "Creada:",
  closedRequestsText5: "entrega por fases",
  closedRequestsText6: "Ver",

  ordersPanelText1: "Pedidos",
  ordersPanelText2: "Pedidos Abiertos",
  ordersPanelText3: "Pedidos Cerrados",

  openOrdersText1: "Pedido #",
  openOrdersText2: "está programado para",
  openOrdersText3: "Por determinar (entrega por fases)",
  openOrdersText4: "Ver",
  openOrdersText5: "Sin pedidos abiertos",
  openOrdersText6: "Actualmente no tienes pedidos pendientes. ¡Los nuevos pedidos aparecerán una vez recibidos!",
  openOrdersText7: "Pedidos Abiertos",

  closedOrdersText1: "Sin Pedidos Cerrados",
  closedOrdersText2: "Actualmente no tienes pedidos cerrados. ¡Los pedidos aparecerán aquí cuando se cierren!",
  closedOrdersText3: "Pedido #",
  closedOrdersText4: "estaba programado para",
  closedOrdersText5: "entrega por fases",
  closedOrdersText6: "Ver",
};

export default dashboard; 