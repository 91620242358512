const order = {
  orderText0: "Por favor nota: Cualquier cambio realizado en tu cotización (eliminar artículos o dividir envíos) puede resultar en cambios en el costo de los artículos y el flete.",
  orderText1: "Fecha de Entrega:",
  orderText2: "Por determinar (entrega por fases)",
  orderText3: "Para preguntas sobre facturación o pagos, por favor envía un correo a",
  orderText4: "Artículos por camión",
  orderText5: "Camión #",
  orderText6: "por",
  orderText7: "cada uno y",
  orderText8: "total",
  orderText9: "Subtotal:",
  orderText10: "Cargo por Entrega",
  orderText11: "Impuestos",
  orderText12: "Total:",
  orderText13: "- Se aplicaron impuestos si no tenías una licencia de revendedor registrada con nosotros. ¿Tienes una licencia?",
  orderText14: "Contáctanos",
  orderText15: "Volver",
  orderText16: "Compartir",
  orderText17: "- Tu total de entrega se basa en una entrega única para todos los materiales listados. Las entregas divididas resultarán en costos adicionales.",
  orderText18: "Artículos por camión",
  orderText19: "Los artículos del pedido están agrupados por camión. Verifica un camión para ver los artículos que serán entregados en ese camión.",
  orderText20: "Esta es una copia de tu pedido original. Para detalles relacionados con cambios, devoluciones o reembolsos, por favor consulta tu factura de Xero o envía un correo a",

  // términos y condiciones del pedido
  termsText1: "Términos y Condiciones",
  termsText2: "Las cotizaciones son válidas por 7 días y están sujetas a cambios según disponibilidad.",
  termsText3: "Cualquier cambio realizado en las cotizaciones (agregar o eliminar artículos) resultará en fluctuaciones de precio en la cotización general.",
  termsText4: "Se aplica una tarifa mínima de reposición del 20% a TODOS los pedidos cancelados.",
  termsText5: "Un representante autorizado o representantes de tu organización deben estar presentes en el sitio de entrega el día de la entrega junto con el equipo y el personal necesario para descargar el camión de entrega.",
  termsText6: "Los artículos que se devolverán o intercambiarán deberán dejarse en el vehículo de entrega para ser devueltos al proveedor.",
  termsText7: "No se aceptarán devoluciones ni intercambios después de la entrega.",

  // modal de compartir
  shareModal1: "Compartir Pedido",
  shareModal2: "Compartir este pedido con:",
  shareModal3: "Se requiere un correo electrónico válido",
  shareModal4: "Compartir",
  shareModal5: "Cancelar",

  orderQueryErrorText1: "Ocurrió un error al cargar tu pedido, asegúrate de tener conexión a internet o contacta a Jules en",
  orderQueryErrorText2: "Ocurrió un error al cargar tu pedido, contacta a Jules en",
  orderQueryErrorText3: "para asistencia.",
  orderQueryErrorText4: "Volver",
  orderQueryErrorText5: "Intentar de nuevo",

  useShareOrderText1: "Éxito",
  useShareOrderText2: "Pedido: #{{orderId}} compartido con {{email}}",
  useShareOrderText3: "Error",
  useShareOrderText4: "No se pudo compartir el pedido. Envía un correo a william@bidscape.com para obtener ayuda.",
};

export default order; 