import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import en from "./en";
import es from "./es";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: en,
      },
      es: {
        translation: es,
      },
    },
    fallbackLng: "en",
    detection: {
      order: ['navigator', 'htmlTag', 'path', 'subdomain'],
      lookupFromPathIndex: 0,
      caches: ['localStorage']
    },
  } as const);
