const wrapperTranslations = {
  footerText1: `©Copyright ${new Date().getFullYear()} Bidscape, Inc`,
  footerText2: "Privacidad",
  footerText3: "Términos",

  headerText1: "Iniciar sesión",
  headerText2: "Cambiar usuario",
  headerText3: "Cerrar sesión",
  headerText4: "Navegación",
  headerText5: "Cambiar tema",
  headerText6: "Cambiar Usuario",
  headerText7: "Cerrar",
  headerText8: "Cambiar idioma",

  switchYardText1: "Buscar usuario",

  useSwitchUserText1: "Éxito",
  useSwitchUserText2: "seleccionado como usuario activo",
  useSwitchUserText3: "Error",
  useSwitchUserText4: "Error al cambiar de usuario",
};

export default wrapperTranslations; 