import { Button, Group, List, Space, Text, Title, Progress } from "@mantine/core";
import { IconArrowRight, IconPlus } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Conditional from "../../components/Conditional";
import { FadeTransition, SlideTransition } from "../../components/Transitions";
import useProjectCartItems from "../../hooks/useCartItems";
import PlantEntryItem from "./PlantEntryItem";

interface Props {
  onNext: () => void;
  transitionDirection: "right" | "left";
}

const SIZE_BREAKPOINTS = [
  { value: 102200, multiplier: 1 },    // 150 needed
  { value: 102700, multiplier: 2 },    // 75 needed
  { value: 103900, multiplier: 3 },    // 50 needed
  { value: 104800, multiplier: 18.75 }, // 8 needed
  { value: 106200, multiplier: 37.5 },  // 4 needed
  { value: 108500, multiplier: 75 },    // 2 needed
  { value: 108601, multiplier: 150 },   // 1 needed
];

const getMultiplierForSize = (sizeValue?: number) => {
  if (!sizeValue) return 0;
  
  // If size is less than first breakpoint, use multiplier of 1
  if (sizeValue < SIZE_BREAKPOINTS[0].value) {
    return SIZE_BREAKPOINTS[0].multiplier;
  }
  
  // Find the first breakpoint where the size value is less than the next threshold
  const breakpoint = SIZE_BREAKPOINTS.find((bp, index) => {
    const nextBp = SIZE_BREAKPOINTS[index + 1];
    return sizeValue >= bp.value && (!nextBp || sizeValue < nextBp.value);
  });

  return breakpoint?.multiplier || 0;
};

const PlantEntry: React.FC<Props> = ({ onNext, transitionDirection }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { cartItems, removeCartItem } = useProjectCartItems();

  const getProjectMinimumPercentage = () => {
    const totalPoints = cartItems.reduce((sum, item) => {
      const multiplier = getMultiplierForSize(item.size?.value);
      return sum + (multiplier * (item.quantity || 0));
    }, 0);

    const pointsNeeded = 150;
    return Math.min(100, Math.round((totalPoints / pointsNeeded) * 100));
  };

  const isProjectMinimumMet = () => getProjectMinimumPercentage() === 100;

  return (
    <SlideTransition direction={transitionDirection}>
      <Container>
        <Title>{t("requestPlantEntryText1")}</Title>
        <Text>{t("requestPlantEntryText2")}</Text>
        <Space h={"lg"} />
        
        <Conditional renderIf={cartItems.length === 0}>
          <Group justify="center" mt="xl">
            <Button
              onClick={() => navigate("/")}
              leftSection={<IconPlus />}
              color="green"
              size="lg"
            >
              {t("requestPlantEntryText12")}
            </Button>
          </Group>
        </Conditional>

        <Conditional renderIf={cartItems.length > 0}>
          <List>
            {cartItems.map((item, index) => (
              <PlantEntryItem key={index} item={item} removeCartItem={removeCartItem} />
            ))}
          </List>

          <Conditional renderIf={!isProjectMinimumMet()}>
            <MinimumRequirementContainer>
              <Text size="sm" mb="xs" c="dimmed">
                {t("requestProjectMinimumText")}
              </Text>
              <Progress 
                color="green" 
                size="md" 
                value={getProjectMinimumPercentage()} 
                radius="xl"
              />
            </MinimumRequirementContainer>
          </Conditional>

          <FadeTransition>
            <Group justify="center" mt="md">
              <Button
                onClick={() => navigate("/")}
                leftSection={<IconPlus />}
                color="green"
              >
                {t("requestPlantEntryText11")}
              </Button>
              <Conditional renderIf={isProjectMinimumMet()}>
                <Button
                  data-testid="finish-plant-entry-btn"
                  onClick={onNext}
                  rightSection={<IconArrowRight />}
                  variant="outline"
                  color="green"
                >
                  {t("requestPlantEntryText8")}
                </Button>
              </Conditional>
            </Group>
          </FadeTransition>
        </Conditional>
      </Container>
    </SlideTransition>
  );
};

const Container = styled.div`
  margin: auto;
  max-width: 928px;

  @media (max-width: 576px) {
    border-top: 1px solid #eee;
  }
`;

const MinimumRequirementContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 8px;
  background-color: var(--mantine-color-dark-6);

  [data-mantine-color-scheme="light"] & {
    background-color: var(--mantine-color-gray-0);
  }
`;

PlantEntry.defaultProps = {
  transitionDirection: "right",
};

export default PlantEntry;
