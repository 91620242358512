import { Navigate, RouteObject } from "react-router-dom";
import CategoriesListing from "../browse/CategoriesListing";
import PlantDetails from "../browse/PlantDetails";
import PlantsListing from "../browse/PlantsListing";
import ContractorListing from "../contractors/ContractorListing";
import Dashboard from "../dashboard/Dashboard";
import DefaultPhoto from "../defaultPhoto/DefaultPhoto";
import Home from "../home/Home";
import AuthenticateLink from "../landing/AuthenticateLink";
import LinkSent from "../landing/LinkSent";
import Login from "../landing/Login";
import Order from "../orders/Order";
import Payment from "../payment/Payment";
import PaymentSuccess from "../payment/PaymentSuccess";
import Photos from "../photos/Photos";
import Estimate from "../quotes/Estimate";
import Quotes from "../quotes/Quote";
import PublicRequest from "../requests/Requests";
import TopPlantsShowcase from "../browse/TopPlantsShowcase";

export const getRoutes = (isLoggedIn?: boolean): RouteObject[] => [
  {
    path: "/",
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "categories",
        element: <CategoriesListing />,
      },
      {
        path: "plants",
        element: <PlantsListing />,
      },
      {
        path: "new-request",
        element: <PublicRequest />,
      },
      {
        path: "get-started",
        element: <PublicRequest />,
      },
      {
        path: "dashboard",
        element: isLoggedIn ? <Dashboard /> : <Navigate to="/login" />,
      },
      {
        path: "orders/:orderId",
        element: isLoggedIn ? <Order /> : <Navigate to="/login" />,
      },
      {
        path: "top-plants",
        element: <TopPlantsShowcase />,
      },
    ],
  },
  {
    path: "/login",
    children: [
      {
        index: true,
        element: <Login />,
      },
      {
        path: "link-sent",
        element: <LinkSent />,
      },
      {
        path: ":key/:value",
        element: <AuthenticateLink />,
      },
    ],
  },
  {
    path: "/photos",
    children: [
      {
        path: "view/:quoteItemId",
        element: isLoggedIn ? <Photos /> : <Navigate to="/login" />,
      },
      {
        path: "default/:estimateItemId",
        element: isLoggedIn ? <DefaultPhoto /> : <Navigate to="/login" />,
      },
    ],
  },
  {
    path: "/quotes",
    children: [
      {
        path: "quote/:id",
        element: isLoggedIn ? <Quotes /> : <Navigate to="/login" />,
      },
      {
        path: "estimate/:id",
        element: isLoggedIn ? <Estimate /> : <Navigate to="/login" />,
      },
    ],
  },
  {
    path: "/plant",
    children: [
      {
        index: true,
        element: <Navigate to="/plants" />,
      },
      {
        path: ":plantId",
        element: <PlantDetails />,
      },
      {
        path: ":plantId/:plantName",
        element: <PlantDetails />,
      },
      {
        path: ":plantId/:plantName/:locationId",
        element: <PlantDetails />,
      },
    ],
  },
  {
    path: "/category",
    children: [
      {
        index: true,
        element: <Navigate to="/categories" />,
      },
      {
        path: ":categoryId",
        element: <PlantsListing />,
      },
      {
        path: ":categoryId/:categoryName",
        element: <PlantsListing />,
      },
    ],
  },
  {
    path: "payment",
    children: [
      {
        index: true,
        element: isLoggedIn ? <Payment /> : <Navigate to="/login" />,
      },
      {
        path: "success",
        element: isLoggedIn ? <PaymentSuccess /> : <Navigate to="/login" />,
      },
    ],
  },
  {
    path: "firm/:id/:hmac",
    element: <ContractorListing />,
  },
  {
    path: "*",
    element: <Navigate to="/not-found" />,
  },
];
export default getRoutes;
