const defaultPhoto = {
  defaultPhotoText1: "ACTUALMENTE SELECCIONADO -",
  defaultPhotoText2: "- Artículo #",
  defaultPhotoText3: "Altura:",
  defaultPhotoText4: "Ancho:",
  defaultPhotoText5: "Precio Unitario Actual:",
  defaultPhotoText6: "Esta es una foto representativa",
  defaultPhotoText7: "Ver opciones representativas de nuestras fotos de stock internas",
  defaultPhotoText8: "Cambiar foto",
  defaultPhotoText9: "No hay otras opciones disponibles.",
  defaultPhotoText10: "OPCIÓN #",
  defaultPhotoText11: "- Artículo #",
  defaultPhotoText12: "Altura:",
  defaultPhotoText13: "Ancho:",
  defaultPhotoText14: "Precio Unitario Ajustado: ",
  defaultPhotoText15: "ya no estará disponible si se selecciona",
  defaultPhotoText16: "estará disponible si se selecciona",
  defaultPhotoText17: "será sustituido por",
  defaultPhotoText18: "si se selecciona",
  defaultPhotoText19: "ya no será sustituido si se selecciona",
  defaultPhotoText20: "Precio Unitario Ajustado: ",
  defaultPhotoText21: "Calculando...",
  defaultPhotoText22: "Seleccionar como predeterminado",
  defaultPhotoText23: "Volver",
  defaultPhotoText24: "Los precios unitarios ajustados reflejan los costos adicionales incurridos en el resto del pedido como resultado de la selección del artículo.",
  defaultPhotoText25: "Editar Foto",

  noPhotoOptionsText1: "Sin opciones",
  noPhotoOptionsText2: "No hay otras opciones de fotos disponibles, por favor contacta a Jules en",
  noPhotoOptionsText3: "para asistencia.",

  defaultPhotoQueryErrorText1: "Ocurrió un error al cargar la foto predeterminada, asegúrate de tener conexión a internet o contacta a Jules en",
  defaultPhotoQueryErrorText2: "Ocurrió un error al cargar la foto predeterminada, contacta a Jules en",
  defaultPhotoQueryErrorText3: "para asistencia.",
  defaultPhotoQueryErrorText4: "Volver",
  defaultPhotoQueryErrorText5: "Intentar de nuevo",

  useSetCartItemPhotoText1: "Cambio de foto predeterminada exitoso",
  useSetCartItemPhotoText2: "Error al cargar opciones de foto predeterminada",

  defaultPhotoOptionsQueryErrorText1: "Ocurrió un error al cargar las opciones de fotos, asegúrate de tener conexión a internet o contacta a Jules en",
  defaultPhotoOptionsQueryErrorText2: "Ocurrió un error al cargar las opciones de fotos, contacta a Jules en",
  defaultPhotoOptionsQueryErrorText3: "para asistencia.",
  defaultPhotoOptionsQueryErrorText4: "Volver",
  defaultPhotoOptionsQueryErrorText5: "Intentar de nuevo",
};

export default defaultPhoto; 