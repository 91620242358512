const contractorTranslation = {
  useSaveFirmMetadataText1: "Información de la empresa actualizada",
  useSaveFirmMetadataText2: "La información de tu empresa se ha actualizado exitosamente",
  useSaveFirmMetadataText3: "Error al actualizar la información de la empresa",
  useSaveFirmMetadataText4: "Ocurrió un error al actualizar la información de tu empresa",

  contractorListingText1: "Administrar mi listado",
  contractorListingText2: "Detalles de la Empresa",
  contractorListingText3: "Por favor revisa y corrige los detalles de tu empresa",
  contractorListingText4: "Nombre de la empresa",
  contractorListingText5: "Por favor ingresa el nombre de tu empresa",
  contractorListingText6: "Correo electrónico",
  contractorListingText7: "Se requiere un correo electrónico válido",
  contractorListingText8: "Número de teléfono",
  contractorListingText9: "Se requiere un teléfono válido",
  contractorListingText10: "Sitio web",
  contractorListingText11: "Dirección",
  contractorListingText12: "Se requiere una dirección válida",
  contractorListingText13: "Servicios proporcionados",
  contractorListingText14: "Por favor selecciona los servicios que proporcionas",
  contractorListingText15: "Paisajismo Residencial",
  contractorListingText16: "Paisajismo Comercial",
  contractorListingText17: "Mantenimiento",
  contractorListingText18: "Por favor selecciona al menos un servicio proporcionado",
  contractorListingText19: "Volumen del proyecto",
  contractorListingText20: "Por favor ingresa el volumen de tu proyecto",
  contractorListingText21: "Tamaño mínimo del proyecto",
  contractorListingText22: "Por favor ingresa el tamaño mínimo de tu proyecto",
  contractorListingText23: "Tamaño máximo del proyecto",
  contractorListingText24: "Por favor ingresa el tamaño máximo de tu proyecto",
  contractorListingText25: "Enviar",
};

export default contractorTranslation; 