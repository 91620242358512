const quotes = {
  quotesText0: "Cotización",
  quotesText1: "Fecha Estimada de Entrega",
  quotesText2: "por",
  quotesText3: "cada uno y",
  quotesText4: "total",
  quotesText5: "fue",
  quotesText6: "sustituido",
  quotesText7: "con",
  quotesText8: "cada uno y",
  quotesText9: "total",
  quotesText10: "Solicitar Fotos",
  quotesText11: "Fotos Solicitadas",
  quotesText12: "Opciones de Fotos",
  quotesText13: "Editar",
  quotesText14: "fue eliminado",
  quotesText15: "no está disponible",
  quotesText16: "Agregar",
  quotesText17: "Solicitar Sustitución",
  quotesText18: "Subtotal:",
  quotesText19: "Cargo por Entrega",
  quotesText20: "Impuestos",
  quotesText21: "Total:",
  quotesText22: "Descuento:",
  quotesText23: "- Se aplican impuestos si no tienes una licencia de revendedor registrada con nosotros. ¿Tienes una licencia?",
  quotesText24: "Contáctanos",
  quotesText25: "Pagar con tarjeta",
  quotesText26: "Pagar con términos",
  quotesText27: "Volver",
  quotesText28: "Compartir",
  quotesText29: "Descargar cotización como Excel (.xlsx)",
  quotesText30: "Agregar planta",
  quotesText31: "¿Te gustaría agregar una planta?",
  quotesText32: "Confirmar",
  quotesText33: "Editar Planta",
  quotesText34: "¿Qué te gustaría hacer con tu",
  quotesText35: "Eliminar",
  quotesText36: "Eliminar Planta",
  quotesText37: "Eliminar tu",
  quotesText38: "puede impactar el precio y la disponibilidad del resto de tu pedido.",
  quotesText39: "Además, la eliminación de artículos reduce tu",
  quotesText40: "Descuento",
  quotesText41: "En lugar de eliminar el artículo, considera usar nuestra herramienta de negociación de precios para intentar alcanzar tus objetivos de precio.",
  quotesText42: "Negociar Precio",
  quotesText43: "Eliminar",
  quotesText44: "Negociar Precio",
  quotesText45: "Establece tu precio unitario objetivo para",
  quotesText46: "Precio Objetivo:",
  quotesText47: "Precio",
  quotesText48: "Se requiere un precio válido",
  quotesText49: "Nota: La negociación es totalmente automatizada, pero toma hasta 24 horas en finalizarse.",
  quotesText50: "Comenzar Negociación",
  quotesText51: "Confirmar Solicitud de Fotos",
  quotesText52: "Por favor, solicita fotos solo para artículos que realmente estés considerando comprar.",
  quotesText53: "Agradecemos el uso de Bidscape para obtener estimaciones de proyectos, pero para maximizar tus opciones, enviamos solicitudes de fotos a todos los viveros que reportaron tener el artículo (en algunos casos pueden ser docenas), y toma tiempo y esfuerzo significativo responder.",
  quotesText54: "Te animamos a tener esto en cuenta al hacer tus solicitudes.",
  quotesText55: "Confirmar Solicitud de Fotos",
  quotesText56: "Compartir cotización",
  quotesText57: "Compartir esta cotización con:",
  quotesText58: "Correo electrónico",
  quotesText59: "Se requiere un correo electrónico válido",
  quotesText60: "Compartir",
  quotesText61: "Confirmar Solicitud de Sustitución",
  quotesText62: "Solicitar una sustitución tomará hasta 24 horas en completarse.",
  quotesText63: "Confirmar solicitud",
  quotesText64: "Solicitar financiamiento",
  quotesText65: "Bidscape puede ofrecer términos para tu material vegetal, dependiendo de la factura. Contacta a",
  quotesText66: "para detalles.",
  quotesText67: "Excel (.xlsx)",
  quotesText68: "- Tu total de entrega se basa en una entrega única para todos los materiales listados. Las entregas divididas resultarán en costos adicionales.",
  quotesText69: "individualmente",
  quotesText70: "cada uno (en paquete)",
  quotesText71: "Subtotal individual: ",
  quotesText72: "Subtotal en paquete: ",
  quotesText73: "ahorrado",
  quotesText74: "o",
  quotesText75: "Solicitar Fotos Actuales",
  quotesText76: "Ver Foto",
  quotesText77: "Esta es una foto representativa",
  quotesText78: "Cerrar",
  quotesText79: "cada uno",
  quotesText80: "Por favor nota: Cualquier cambio realizado en tu cotización (eliminar artículos o dividir envíos) puede resultar en cambios en el costo de los artículos y el flete.",
  quotesText81: "Esta cotización ha expirado y ha sido archivada. Para renovarla, por favor contacta a Jules en",
  quotesText82: "Cargo por Servicio",
  quotesText83: "(Los pagos con tarjeta de crédito tienen un cargo adicional de procesamiento del 3%)",

  quotesTermsText1: "Términos y Condiciones",
  quotesTermsText2: "Las cotizaciones son válidas por 7 días y están sujetas a cambios según disponibilidad.",
  quotesTermsText3: "Cualquier cambio realizado en las cotizaciones (agregar o eliminar artículos) resultará en fluctuaciones de precio en la cotización general.",
  quotesTermsText4: "Se aplica una tarifa mínima de reposición del 20% a TODOS los pedidos cancelados.",
  quotesTermsText5: "Un representante autorizado o representantes de tu organización deben estar presentes en el sitio de entrega el día de la entrega junto con el equipo y el personal necesario para descargar el camión de entrega.",
  quotesTermsText6: "Los artículos que se devolverán o intercambiarán deberán dejarse en el vehículo de entrega para ser devueltos al proveedor.",
  quotesTermsText7: "No se aceptarán devoluciones ni intercambios después de la entrega.",

  useAddItemText1: "Exitoso",
  useAddItemText2: "Artículo agregado exitosamente.",
  useAddItemText3: "Error",
  useAddItemText4: "No se pudo agregar el artículo. Envía un correo a jules@bidscape.com para obtener ayuda.",

  useAddPhotoRequestText1: "Exitoso",
  useAddPhotoRequestText2: "¡Fotos solicitadas exitosamente!",
  useAddPhotoRequestText3: "Error",
  useAddPhotoRequestText4: "No se pudieron solicitar las fotos. Envía un correo a jules@bidscape.com para obtener ayuda.",

  useAddSubRequestText1: "Exitoso",
  useAddSubRequestText2: "Sustitución solicitada exitosamente.",
  useAddSubRequestText3: "Error",
  useAddSubRequestText4: "No se pudo solicitar la sustitución. Envía un correo a jules@bidscape.com para obtener ayuda.",

  useNegotiateText1: "Exitoso",
  useNegotiateText2: "Negociación iniciada exitosamente.",
  useNegotiateText3: "Error",
  useNegotiateText4: "Ocurrió un error, por favor contacta a heather@bidscape.com",

  useShareRfqText1: "Exitoso",
  useShareRfqText2: "Rfq compartida exitosamente.",
  useShareRfqText3: "Error",
  useShareRfqText4: "No se pudo compartir la Rfq. Envía un correo a jules@bidscape.com para obtener ayuda.",

  useRemoveItemText1: "Exitoso",
  useRemoveItemText2: "Artículo eliminado exitosamente.",
  useRemoveItemText3: "Error",
  useRemoveItemText4: "No se pudo eliminar el artículo. Envía un correo a jules@bidscape.com para obtener ayuda.",

  useCheckAvailabilityText1: "Exitoso",
  useCheckAvailabilityText2: "¡{{cartName}} está siendo verificado con {{numSent}} viveros!",
  useCheckAvailabilityText3: "Error",
  useCheckAvailabilityText4: "Ocurrió un error al verificar la disponibilidad de {{cartName}}.",

  quoteQueryErrorText1: "Ocurrió un error al cargar tu cotización, asegúrate de tener conexión a internet o contacta a Jules en",
  quoteQueryErrorText2: "Ocurrió un error al cargar tu cotización, contacta a Jules en",
  quoteQueryErrorText3: "para asistencia.",
  quoteQueryErrorText4: "Volver",
  quoteQueryErrorText5: "Intentar de nuevo",
};

export default quotes; 